<template>
  <v-main>
    <v-container>
      <v-layout align-center justify-center> </v-layout>
      <h3 class="mb-5 mt-5">Question List</h3>
      <v-text-field
        placeholder="Search Question"
        v-model="searchQuery"
        prepend-icon="mdi-magnify"
        clearable
      >
        Search Question
      </v-text-field>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div v-if="loading">Loading...</div>
      <v-btn to="/createquestion"> Create Question </v-btn>
      <v-data-table
        :headers="headers"
        :items="filterQuestion"
        :items-per-page="20"
        item-key="`${id}-${languageId}`"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Question List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.language_id="{ item }">
          {{ languageList.find((x) => x.value == item.language_id).name }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item.id)">
            mdi-pencil
          </v-icon>
          <!-- <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon> -->
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { FETCH_QUESTIONLIST, GET_QUESTION_DETAIL } from "../store/actions.type";
import { languageList } from "../common/ref_data";
export default {
  name: "QuestionList",
  data() {
    return {
      lectures: [],
      questionList: [],
      searchQuery: "",
      info: null,
      loading: true,
      errored: false,
      languageList: languageList,
      headers: [
        {
          text: "Languages",
          align: "start",
          sortable: true,
          value: "language_id",
        },
        { text: "Question Text", value: "question_text" },
        { text: "Answer", value: "answer" },
        { text: "LimitedTime", value: "limitTimeSecond" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    filterQuestion() {
      if (this.searchQuery) {
        return this.questionList.filter((item) => {
          return item.question_text
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      } else {
        return this.questionList;
      }
    },
  },
  mounted() {
    this.$store
      .dispatch(FETCH_QUESTIONLIST)
      .then((data) => {
        this.loading = false;
        this.questionList = data;
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  created() {},
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    editItem(id) {
      this.$store
        .dispatch(GET_QUESTION_DETAIL, id)
        .then((data) => {
          this.loading = false;
          var question = data;
          localStorage.setItem("question", JSON.stringify(question));
          this.$router.push({
            name: "EditQuestion",
            params: {
              questionId: id,
            },
          });
          console.log(question);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
